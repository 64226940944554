<template>
  <b-card-actions
    :title="$t('HelpDesk Statistics')"
    action-collapse
    no-body
    class="card-statistics"
  >
    <widget-loader v-if="!data.length" />
    <b-card-body
      v-else
      class="statistics-body"
    >
      <b-row>
        <b-col
          v-for="item in data"
          :key="item.icon"
          xl="3"
          sm="6"
          :class="item.customClass"
        >
          <b-media no-body>
            <b-media-aside

              class="mr-2"
            >
              <b-avatar
                size="48"
                :style="`background-color: ${item.color}`"
                :variant="!item.color ? 'light-info' : '' "
              >
                <feather-icon
                  size="24"
                  :icon="item.icon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ item.title }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ item.subtitle }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card-actions>
</template>

<script>
import {
  BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,
} from 'bootstrap-vue'
import WidgetLoader from '@/views/modules/core/loader/Widget.vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'

export default {
  name: 'HelpDeskStatistics',
  components: {
    BCardActions,
    WidgetLoader,
    BRow,
    BCol,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  computed: {

  },
}
</script>

<template>
  <section>
    <b-row class="match-height">
      <b-col lg="12">
        <b-alert
          v-if="announcement.length"
          show
          dismissible
          fade
          variant="success"
        >
          <div class="alert-body">
            <span>{{ announcement }}</span>
          </div>
        </b-alert>
      </b-col>
      <b-col
        v-if="helpDesk.length"
        lg="12"
      >
        <help-desk-statistics

          :data="helpDeskStatistics"
        />
      </b-col>
      <b-col lg="8">
        <recent-help-desk
          v-if="helpDesk.length"
          :table-data="helpDesk"
          :fetch-data="fetchData"
        />
        <recent-sales-order
          v-if="salesOrder.length"
          :table-data="salesOrder"
          :fetch-data="fetchData"
        />
      </b-col>
      <b-col lg="4">
        <shortcuts />
        <recent-invoice
          v-if="invoice.length"
          :table-data="invoice"
          :fetch-data="fetchData"
        />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol, BAlert } from 'bootstrap-vue'
import { coreMethods } from '@/mixins'
import api from '@/apis/portal'
import HelpDeskStatistics from './HelpDeskStatistics.vue'
import RecentInvoice from './RecentInvoice.vue'
import RecentHelpDesk from './RecentHelpDesk.vue'
import RecentSalesOrder from './RecentSalesOrder.vue'
import Shortcuts from './Shortcuts.vue'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    Shortcuts,
    HelpDeskStatistics,
    RecentInvoice,
    RecentSalesOrder,
    RecentHelpDesk,
  },
  mixins: [coreMethods],
  data() {
    return {
      fetchData: true,
      helpDeskStatistics: [],
      announcement: {},
      helpDesk: [],
      invoice: [],
      salesOrder: [],
    }
  },
  beforeMount() {
    this.createNavigationMenus()
    // data
    api.fetchAnnouncement().then(data => {
      this.announcement = data.announcement ? data.announcement : {}
    })
    api.fetchRecentRecords().then(data => {
      this.fetchData = false
      this.helpDesk = data.HelpDesk ? data.HelpDesk : []
      this.invoice = data.Invoice ? data.Invoice : []
      this.salesOrder = data.SalesOrder ? data.SalesOrder : []
    })
    api.helpDeskStatistics().then(data => {
      const helpDeskStatistics = []
      const icons = [
        'DiscIcon',
        'FlagIcon',
        'GridIcon',
        'InfoIcon',
        'MessageCircleIcon',
      ]
      Object.keys(data).forEach((status, index) => {
        helpDeskStatistics.push({
          icon: icons[index],
          color: data[status].color,
          title: data[status].count,
          subtitle: status,
          customClass: 'mb-2',
        })
      })
      this.helpDeskStatistics = helpDeskStatistics
    })
  },

  methods: {

  },
}
</script>

<style scoped>

</style>

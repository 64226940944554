<template>
  <b-card
    :title="$t('Quick access')"
    :img-src="require('@/assets/images/illustration/email.svg')"
    img-top
  >
    <h5>{{ $t('What do you want to do?') }}</h5>
    <h5 class="my-2">
      <b-link :to="{ name: 'list-view', params: { module: 'HelpDesk',filter: { ticketstatus:'باز' }} }">
        {{ $t('Show active HelpDesks') }}
      </b-link>
    </h5>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      @click="showEditView"
    >
      {{ $t('Add Record',{ label: singleLabel }) }}
    </b-button>
    <component
      :is="getComponent(this,'EditViewModal','HelpDesk')"
      ref="edit-view"
      module="HelpDesk"
      @onSubmitForm="submitForm"
    />
  </b-card>
</template>

<script>
import {
  BCard, BLink, BButton, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { coreMethods } from '@/mixins'
import EditViewModal from '@/views/modules/core/edit/EditViewModal.vue'
import { getSingleLabelByModuleName } from '@/utils/utils'

export default {
  name: 'Shortcuts',
  components: {
    BCard,
    BLink,
    BImg,
    BButton,
    EditViewModal,
  },
  directives: {
    Ripple,
  },
  mixins: [coreMethods],
  computed: {
    singleLabel() {
      return getSingleLabelByModuleName('HelpDesk')
    },
  },
  methods: {
    showEditView() {
      this.$refs['edit-view'].$refs['modal-edit-view'].show()
    },
    submitForm(data) {
      this.$refs['edit-view'].$refs['modal-edit-view'].hide()
      this.$router.push({ name: 'detail-view', params: { module: 'HelpDesk', id: data.record.id } })
    },
  },
}
</script>
<style scoped>

</style>

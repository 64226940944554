<template>
  <b-card-actions
    :title="$t('Recent Invoices')"
    class="card-transaction"
    action-collapse
    no-body
  >
    <widget-loader v-if="fetchData" />
    <b-card-body v-show="!fetchData">
      <div
        v-if="tableData.length === 0"
        class="d-flex justify-content-center mb-2"
      >
        {{ $t('No matching records found') }}
      </div>
      <div v-else>
        <b-link
          v-for="(invoice,key) in tableData"
          :key="key"
          :to="{ name: 'detail-view', params: { module:'Invoice', id: invoice.id }}"
          class="transaction-item"
        >
          <b-media no-body>
            <b-media-aside>
              <b-avatar
                rounded
                size="42"
                variant="light-info"
              >
                <feather-icon
                  size="18"
                  icon="DollarSignIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body>
              <h6 class="transaction-title">
                {{ invoice.subject }}
              </h6>
              <small>{{ invoice.invoicestatus }}</small>
            </b-media-body>
          </b-media>
          <div
            class="font-weight-bolder text-success"
          >
            {{ invoice.hdnGrandTotal | currencyFormat(invoice.currency) }}
          </div>
        </b-link>
      </div>
    </b-card-body>
  </b-card-actions>
</template>

<script>
import {
  BCardBody, BMediaBody, BMedia, BMediaAside, BAvatar, BLink,
} from 'bootstrap-vue'
import WidgetLoader from '@/views/modules/core/loader/Widget.vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'

export default {
  name: 'RecentInvoice',
  components: {
    BCardActions,
    WidgetLoader,
    BCardBody,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BLink,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    fetchData: {
      type: Boolean,
      default: () => true,
    },
  },
}
</script>

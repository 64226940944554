<template>
  <b-card-actions
    :title="$t('Recent HelpDesk')"
    no-body
    action-collapse
    class="card-company-table"
  >
    <widget-loader v-if="fetchData" />
    <div v-show="!fetchData">
      <div
        v-if="tableData.length === 0"
        class="d-flex justify-content-center mb-3"
      >
        {{ $t('No matching records found') }}
      </div>
      <b-table
        v-else
        :items="tableData"
        responsive
        :fields="fields"
        class="mb-0"
      >
        <!-- label -->
        <template #cell(title)="data">
          <b-link
            :to="{ name: 'detail-view', params: { module:'HelpDesk', id: data.item.id }}"
            class="font-weight-bold"
          >
            {{ data.item.label }}
          </b-link>
        </template>
        <template #cell(statuslabel)="data">
          <b-badge
            pill
            variant="light-secondary"
          >
            {{ data.value }}
          </b-badge>
        </template>
      </b-table>
    </div>
  </b-card-actions>
</template>

<script>
import {
  BTable, BLink, BBadge,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import WidgetLoader from '@/views/modules/core/loader/Widget.vue'

export default {
  name: 'RecentHelpDesk',
  components: {
    WidgetLoader,
    BCardActions,
    BTable,
    BLink,
    BBadge,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    fetchData: {
      type: Boolean,
      default: () => true,
    },
  },

  data() {
    return {
      fields: [
        { key: 'title', label: this.$t('Title') },
        { key: 'statuslabel', label: this.$t('Status') },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
